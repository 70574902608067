


























































































































































































































































































import { AdvancedSelect, Toggle, TwInput, VariableAwareInput, AccessLevelDynamicBadgeCompact } from '@/app/components';
import { VariableType } from '@/app/constants';
import { Variable } from '@/app/interfaces';
import { S } from '@/app/utilities';
import { useAvailableWorkflowModels, useParameter } from '@/modules/workflow-designer/composable';
import { InputParameter, Loop } from '@/modules/workflow-designer/types';
import { PlusCircleIcon, TrashIcon } from '@vue-hero-icons/solid';
import { computed, defineComponent, PropType, ref, Ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { AccessLevel } from '@/modules/access-policy/constants/access-levels.constants';
import ParameterValidation from '../ParameterValidation.vue';

export default defineComponent({
    name: 'ModelParameter',
    props: {
        value: {
            type: Object,
        },
        workflowId: { type: String, required: true },
        name: {
            type: String,
            required: true,
        },
        rules: {
            type: Object,
            default: () => {
                return {};
            },
        },
        parameter: {
            type: Object,
            required: true,
        },
        dataframes: {
            type: Object,
            default: () => {
                return {};
            },
        },
        columnsPerTask: {
            type: Object,
            default: () => {
                return {};
            },
        },
        strict: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        visible: {
            type: Boolean,
            default: true,
        },
        isOnPremise: {
            type: Boolean,
        },
        validationMessages: {
            type: Object,
            default: () => ({}),
        },
        availableVariables: {
            type: Object as PropType<Record<string, Variable>>,
            default: () => {
                return {};
            },
        },
        loops: {
            type: Array as PropType<Loop[]>,
            default: () => [],
        },
        forceUpdate: {
            type: Date,
        },
    },
    components: {
        ParameterValidation,
        VariableAwareInput,
        AdvancedSelect,
        Toggle,
        TwInput,
        PlusCircleIcon,
        TrashIcon,
        AccessLevelDynamicBadgeCompact,
    },
    setup(props, { emit }) {
        // Computed variables needed so that the composable
        // can react to their changes which is otherwise not possible at the moment
        const value = computed((): any => props.value);
        const visible = computed((): boolean => props.visible);
        const validationRef = ref<any>(null);

        const validationValue = ref<any>(props.value);
        const workflowId = computed(() => props.workflowId);

        const { models, loading: loadingModels } = useAvailableWorkflowModels(workflowId, true);

        const processedModels = computed(() => {
            if (R.isNil(models.value)) return [];
            return models.value
                .map((modelAsset: any) => {
                    const file = modelAsset.file;
                    return {
                        id: file.id,
                        name: modelAsset.name,
                        featuresOrdered: file.attributes.feature_order,
                        featuresEncoded: file.attributes.features_encoded,
                        type: file.attributes.type,
                        file: {
                            filename: file.filename,
                            bucket: file.bucket,
                        },
                        purpose: file.attributes.purpose,
                        algorithm: file.attributes.name,
                        assetId: modelAsset.id,
                        status: modelAsset.status,
                        accessLevel: modelAsset.accessLevel,
                    };
                })
                .sort((m1: any, m2: any) => m1.name.localeCompare(m2.name));
        });

        const variableInputType = computed(() => {
            // find variable input type
            // we assume that there is only one loop with one variable for now
            // TODO: eliviate assumption...
            if (props.loops.length > 0 && Object.keys(props.loops[0].variables).length > 0) {
                const firstVariableKey = Object.keys(props.loops[0].variables)[0];
                const firstVariable = props.loops[0].variables[firstVariableKey];
                if (firstVariable === VariableType.Double || firstVariable === VariableType.Integer) return 'number';
            }

            return 'text';
        });

        const parseVariableValue = (variable: string) => {
            if (R.isNil(variable) || R.isEmpty(variable)) return null;
            // parse variable value depending on the type
            // we assume that there is only one loop with one variable for now
            // TODO: eliviate assumption...
            if (variableInputType.value === 'number') {
                return Number(variable);
            }
            return variable.trim();
        };

        // Computes a list of possible values this string can take
        const fixedList = computed(() => {
            if (!props.isOnPremise && props.parameter.type) {
                return processedModels.value.filter((model: any) => model.type === props.parameter.type);
            }
            return null;
        });

        const hasAvailableVariables = computed(() => Object.keys(props.availableVariables).length > 0);
        const loopVariableMappingMode: Ref<boolean> = computed({
            get: () =>
                hasAvailableVariables.value &&
                R.is(Array, currentValue.value) &&
                currentValue.value.some((i: any) => !R.is(String, i)),
            set: (toggle: boolean) => {
                if (toggle) {
                    currentValue.value = [{ variableValue: '', model: null }];
                } else {
                    if (props.parameter.multiple) currentValue.value = [];
                    else currentValue.value = null;
                }
            },
        });

        const showSingleInput = computed(() => props.isOnPremise || !props.parameter.type);

        const { currentValue, change, changeDate, findInitialValue } = useParameter(
            // the parameter definition
            props.parameter as InputParameter,

            // the current value of the parameter
            value as Ref<any>,

            // if the parameter is visible or not
            visible,

            // send explicitly a new value
            (newValue: any) => {
                emit('change', { value: newValue });
            },

            // find initial value function
            (incomingValue: any) => {
                let resultingValue = null;
                if (!S.has('value', incomingValue) || !incomingValue?.value) {
                    // Case no existing value defined
                    if (S.has('default', props.parameter.validation)) {
                        // Case default value defined in validation information
                        resultingValue = props.parameter.validation.default;
                    } else {
                        // Case no default value defined in validation information then take default empty value
                        resultingValue = props.parameter.multiple ? [] : null;
                    }
                } else if (S.has('value', incomingValue) && incomingValue?.value && props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking multiple values
                    resultingValue = R.is(Array, incomingValue.value) ? incomingValue.value : [incomingValue.value];
                } else if (incomingValue?.value && !props.parameter.multiple) {
                    // Case where their is an existing value and the parameter is taking a single value
                    if (showSingleInput.value) {
                        resultingValue = incomingValue.value;
                    } else if (
                        R.is(Array, incomingValue.value) &&
                        incomingValue.value.some((i: any) => !R.is(String, i))
                    ) {
                        resultingValue = incomingValue.value.map((entry: { variableValue: string; model: any }) => {
                            return {
                                variableValue: entry.variableValue,
                                model: entry.model?.id,
                            };
                        });
                    } else {
                        resultingValue = incomingValue.value.id;
                    }
                }
                return resultingValue;
            },

            // on value change
            () => {
                if (!R.isNil(currentValue)) {
                    if (!R.isNil(currentValue.value) && R.isEmpty(currentValue.value)) {
                        currentValue.value = findInitialValue(currentValue.value);
                    }

                    if (showSingleInput.value) {
                        emit('change', { value: currentValue.value });
                    } else {
                        if (loopVariableMappingMode.value) {
                            // if in variable mapping mode then process each entry in the list and set the model information

                            const mappedModels = currentValue.value.map(
                                (item: { variableValue: string; model: string }) => {
                                    const model = item.model
                                        ? processedModels.value.find((m: { id: string }) => m.id === item.model)
                                        : null;

                                    // for each mapped model we provide the variableValue and the normal information
                                    return {
                                        variableValue: parseVariableValue(item.variableValue),
                                        model: model ? { id: model.id, assetId: model.assetId, ...model.file } : null,
                                    };
                                },
                            );

                            emit('change', {
                                value: mappedModels,
                            });
                        } else {
                            const model: any = currentValue.value
                                ? processedModels.value.find((m: any) => m.id === currentValue.value)
                                : null;
                            emit('change', {
                                value: model ? { id: model.id, assetId: model.assetId, ...model.file } : null,
                            });
                        }
                    }
                }
            },
        );

        const selectedModel = computed(() => {
            if (currentValue.value && processedModels.value && processedModels.value.length > 0) {
                return processedModels.value.find((model: any) => model.id === currentValue.value);
            }
            return null;
        });

        const selectedModelMissingError = computed(() => {
            if (
                !currentValue.value &&
                validationValue.value?.value &&
                !processedModels.value.find((model: any) => model.id === validationValue.value?.value?.id)
            )
                return 'The model used in the task is not available anymore. Please select a new one.';
            return null;
        });

        const modelRules = computed(() => {
            if (!props.isOnPremise) return props.rules; // if cloud execution, return current block rules

            // if on-premise execution, apply min/max/regex rules only on the last part of the path (model name)
            const lastPartRules = {
                min: R.pathOr(null, ['min'], props.rules),
                max: R.pathOr(null, ['max'], props.rules),
                regex: R.pathOr(null, ['regex'], props.rules),
            };

            // remove min/max/regex rules from the current rules
            const newRules = R.clone(props.rules);
            delete newRules.min;
            delete newRules.max;
            delete newRules.regex;

            lastPartRules.regex = lastPartRules.regex
                ? (lastPartRules.regex as any).toString().replaceAll('/', '')
                : null;

            // if on-premise execution, add modelpath rule
            newRules.modelpath = JSON.stringify(lastPartRules);

            if (!R.isNil(props.parameter.validation) && R.isNil(props.parameter.type) && !props.isOnPremise) {
                newRules.excluded = models.value.map((model: any) => model.file?.filename);
            }

            return newRules;
        });

        const modelValidationMessages = computed(() => {
            if (!props.isOnPremise) return props.validationMessages;
            return {
                modelpath: `Should be a valid path. ${
                    props.validationMessages.regex
                        ? `The last part of the path should be a valid model name: ${props.validationMessages.regex}`
                        : ''
                } ${props.rules.min ? `Minimum number of characters: ${props.rules.min}.` : ''} ${
                    props.rules.max ? `Maximum number of characters: ${props.rules.max}.` : ''
                }`,
            };
        });

        const alreadySelectedModelIdsFromMapping = computed(() =>
            currentValue.value.reduce((acc: string[], custom: any) => {
                if (custom.model) acc.push(custom.model);
                return acc;
            }, []),
        );

        // calculates if there are any available models for mapping
        const hasAvailableModelsForMapping: Ref<boolean> = computed(() => {
            if (!loopVariableMappingMode.value) return false;

            if (fixedList.value && R.is(Array, fixedList.value))
                return fixedList.value.some(
                    (model: any) => !alreadySelectedModelIdsFromMapping.value.includes(model.id),
                );
            return false;
        });

        // disables models that are no longer available
        const modelsForMappingCustomList = computed(() => {
            if (!loopVariableMappingMode.value) return [];

            if (fixedList.value && R.is(Array, fixedList.value))
                return fixedList.value.map((model: any) => {
                    return {
                        ...model,
                        selectable: !alreadySelectedModelIdsFromMapping.value.includes(model.id),
                    };
                });
            return [];
        });

        const modelMappingErrors = computed(() => {
            if (!loopVariableMappingMode.value) return [];
            const variableUsed: Record<string, number> = {};

            for (let v = 0; v < currentValue.value.length; v++) {
                const entry: { variableValue: string; model: string } = currentValue.value[v];

                // if value not entered yet then skip
                if (R.isNil(entry.variableValue) || R.isEmpty(entry.variableValue)) continue;

                // initialise variable value entry
                if (!R.has(entry.variableValue, variableUsed)) (variableUsed[entry.variableValue] as number) = 0;

                // increase occurrence count by one
                variableUsed[entry.variableValue] += 1;
            }

            return Object.keys(variableUsed).reduce((errors: string[], variableValue: string) => {
                if (variableUsed[variableValue] > 1)
                    errors.push(`Iteration value ${variableValue} used more than once `);
                return errors;
            }, []);
        });

        const addCustomEntry = () => {
            currentValue.value.push({ variableValue: '', model: null });
            change();
        };

        const removeCustom = (index: number) => {
            const newCurrentValue = [...currentValue.value];
            newCurrentValue.splice(index, 1);
            currentValue.value = [...newCurrentValue];
            change();
        };

        const validate = (newValue: string | number) => {
            validationValue.value = newValue;
        };

        const accessLevelText = (accessLevel: AccessLevel) => {
            if (R.isNil(accessLevel)) return;

            switch (accessLevel) {
                case AccessLevel.Private:
                    return 'Visible only to me';
                case AccessLevel.OrganisationLevel:
                    return 'Visible to my organisation';
                case AccessLevel.SelectiveSharing:
                    return 'Selective sharing';
                case AccessLevel.Restricted:
                    return 'Restricted within organisation';
                case AccessLevel.Public:
                    return 'Public access';
                default:
                    return null;
            }
        };

        const statusClasses = {
            available: 'bg-green-200 text-green-800',
            acquired: 'bg-neutral-200 text-neutral-800',
            incomplete: 'bg-secondary-200 text-secondary-800',
        };

        const statusTooltipClasses = {
            available: 'text-green-400',
            acquired: 'text-neutral-400',
            incomplete: 'text-secondary-400',
        };

        const statusSuffix = {
            available: 'is available to you',
            acquired: 'was acquired by your organisation',
            incomplete: 'is incomplete and is only available to you',
        };

        watch(
            () => validationValue.value,
            () => change(false),
        );

        watch(
            () => hasAvailableVariables.value,
            (hasAvailable: boolean) => {
                if (!hasAvailable && loopVariableMappingMode.value) currentValue.value = findInitialValue(null);
                change();
            },
        );

        watch(
            () => props.forceUpdate,
            () => (changeDate.value = new Date()),
        );

        // Set the initial value once models have been fetched
        watch(
            () => processedModels.value,
            () => {
                change();
            },
        );

        return {
            currentValue,
            selectedModel,
            fixedList,
            validationRef,
            changeDate,
            change,
            accessLevelText,
            showSingleInput,
            modelRules,
            modelValidationMessages,
            VariableType,
            validationValue,
            validate,
            hasAvailableVariables,
            loopVariableMappingMode,
            modelsForMappingCustomList,
            hasAvailableModelsForMapping,
            modelMappingErrors,
            variableInputType,
            addCustomEntry,
            removeCustom,
            selectedModelMissingError,
            loadingModels,
            statusClasses,
            statusTooltipClasses,
            statusSuffix,
        };
    },
});
